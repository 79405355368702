export const anchorLinks = [
  {
    anchor: 'team',
    alias: '#team',
    title: 'Speakers',
  },
  {
    anchor: 'program',
    alias: '#program',
    title: 'Agenda',
  },
];
