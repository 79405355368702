import React from 'react';
import { Title, Icon, Image } from '@landing/ui';
import { useStateModal } from 'utils/hooks';
import { PlayerModal } from '@landing/modals';

import * as styles from './Slide.module.scss';

export default function Slide({ cover, title, description, duration, videoSrc, startDate }) {
  const { modalState, showModal, hideModal } = useStateModal();

  return (
    <>
      <div role="button" className={styles.item}>
        <div className={styles.pictureWrapper} onClick={showModal}>
          <Image className={styles.picture} classImage={styles.img} src={cover} alt={cover.alt} />
          <Icon name="video-play-circle" className={styles.playIcon} />
          <div className={styles.time}>{duration}</div>
        </div>
        <div className={styles.text}>
          <Title className={styles.title} color="white">
            {title}
          </Title>
          <p className={styles.subtitle}>{description}</p>
          <p className={styles.date}>{startDate}</p>
        </div>
      </div>
      <PlayerModal isOpen={modalState.isOpen} closeModalCallback={hideModal} data={{ videoSrc }} />
    </>
  );
}
