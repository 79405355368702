import React, { lazy, Suspense } from 'react';
import { Button, Container } from 'components/UI';
import { Icon, Title } from '@landing/ui';
import { useIsSsr } from 'utils/hooks';
import { modal as modalStore } from 'store';

import * as styles from './Intro.module.scss';

const Animation = lazy(() => import('components/UI/Animation'));

export default function Intro({ title, subtitle, date, time, format, button }) {
  const isSsr = useIsSsr();

  const callModal = () => {
    modalStore.open('request-consultation');
  };

  return (
    <Container className={styles.container} background="transparent">
      <div className={styles.layout}>
        <Title className={styles.title} tag="h1" color="white">
          {title}
        </Title>
        <p className={styles.subtitle}>{subtitle}</p>
        <div className={styles.details}>
          <div className={styles.detailsItem}>
            <Icon name="calendar" className={styles.detailIcon} />
            <span>{date}</span>
          </div>
          <div className={styles.detailsItem}>
            <Icon name="clock" className={styles.detailIcon} />
            <span>{time}</span>
          </div>
          <div className={styles.detailsItem}>
            <Icon name="marker" className={styles.detailIcon} />
            <span>{format}</span>
          </div>
        </div>
        <Button className={styles.button} onClick={callModal}>
          {button}
        </Button>
        {!isSsr && (
          <Suspense fallback={null}>
            <Animation preserveAspectRatio className={styles.animation} name="intro" />
          </Suspense>
        )}
      </div>
    </Container>
  );
}
