import React, { lazy, Suspense } from 'react';

import { Head as SEO } from '@landing/components';
import Header from 'components/Header';
import { anchorLinks } from 'define/anchorLinks';
import Intro from 'components/Intro';
import Team from 'components/Team';
import EventProgram from 'components/EventProgram';
import Interviews from 'components/Interviews';
import Thoughts from 'components/Thoughts';
import ContactUs from 'components/ContactUs';
import Footer from 'components/Footer';
import CookiesPolicy from '../components/CookiesPolicy/CookiesPolicy';
import { useIsSsr } from 'utils/hooks';

import data from './data.json';

const RequestConsultation = lazy(() =>
  import('components/Modals').then(({ RequestConsultation }) => ({
    default: RequestConsultation,
  })),
);

const SuccessModal = lazy(() =>
  import('components/Modals').then(({ SuccessModal }) => ({
    default: SuccessModal,
  })),
);

const ErrorModal = lazy(() =>
  import('components/Modals').then(({ ErrorModal }) => ({
    default: ErrorModal,
  })),
);

const ConsentModal = lazy(() =>
  import('components/Modals').then(({ ConsentModal }) => ({
    default: ConsentModal,
  })),
);

export default function Index() {
  const isSsr = useIsSsr();

  return (
    <>
      <Header anchorLinks={anchorLinks} />
      <Intro {...data.intro} />
      <Team {...data.team} />
      <EventProgram {...data.eventProgram} />
      <Interviews {...data.interviews} />
      <Thoughts {...data.thoughts} />
      <ContactUs {...data.contactUs} />
      <Footer anchorLinks={anchorLinks} />
      <CookiesPolicy />
      {!isSsr && (
        <Suspense fallback={null}>
          <RequestConsultation />
          <SuccessModal />
          <ErrorModal />
          <ConsentModal />
        </Suspense>
      )}
    </>
  );
}

export const Head = () => <SEO {...data.head} />;
