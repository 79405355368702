import React, { useContext, useEffect, useState } from 'react';
import cn from 'classnames';
import { useField, useFormikContext } from 'formik';
import { ErrorsShakeHubContext, ThemeContext } from 'components/forms/utils';
import { FormError } from 'components/forms';

import * as styles from './FormField.module.scss';

export const FormField = ({ Component, className, inModal, onClick, ...props }) => {
  const [focused, setFocused] = useState(false);
  const formik = useFormikContext();
  const [field, meta, helpers] = useField(props.name);
  const isErrorActive = meta.error && meta.touched;
  const errorsShakeHub = useContext(ErrorsShakeHubContext);
  const theme = useContext(ThemeContext);

  const onChange = value => {
    if (typeof props.onChange === 'function') {
      props.onChange(value, formik);
    }

    helpers.setValue(value);
  };

  let preparedProps = {
    formik,
    errorsShakeHub,
    isErrorActive,
    theme,
    focused,
    setFocused,
    inModal,
    ...props,
    onClick,
    onChange,
    onClose: () => {
      helpers.setTouched(true);

      if (typeof props.onClose === 'function') {
        props.onClose();
      }
    },
  };

  if (typeof props.middleware === 'function') {
    preparedProps = props.middleware(preparedProps);
  }

  useEffect(() => {
    if (typeof props.getInitialValue === 'function' && focused) {
      preparedProps.onChange(props.getInitialValue());
    }
  }, [focused]);

  return (
    <div
      className={cn(styles.formField, preparedProps.withoutError && styles.withoutError, className)}
      onClick={onClick}
    >
      <Component field={field} {...preparedProps} />
      {!preparedProps.withoutError && <FormError value={meta.error} active={meta.touched} />}
    </div>
  );
};
