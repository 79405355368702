import React, { useContext, useState, useEffect } from 'react';
import Api from 'api';
import { FormGrid, FormField, FormRow, FormColumn, FormSubmit } from 'components/forms';
import { Input } from 'components/UI';
import withForm from 'components/forms/withForm';
import { ErrorsShakeHubContext, findFirstFieldWithError } from 'components/forms/utils';
import { corporateFormSchema } from 'components/forms/validationSchemas';
import { CaptchaInfo } from '@landing/ui';
import { pushCustomSubmitEvent } from 'utils';
import { getCountryCode } from 'utils/localised';
import { phoneCode } from 'define/locale';

import * as styles from './CorporateForm.module.scss';

export default function CorporateForm({
  id = 'himss-webinar-2024',
  formName = 'himss-webinar-2024',
  closeModal,
  onSuccess,
  additionalFormData,
  className,
  getAdditionalFormData,
  AdditionalInformationForForm = '',
  inModal = false,
  captchaInfo,
  withNda = true,
}) {
  const Form = ({
    handleSubmit,
    fields,
    isValid,
    values,
    errors,
    isSubmitting,
    status,
    setStatus,
  }) => {
    const errorsShakeHub = useContext(ErrorsShakeHubContext);
    const [btnPressed, setBtnPressed] = useState(false);

    useEffect(() => {
      if (!isValid && btnPressed) {
        errorsShakeHub.dispatchEvent(new CustomEvent('shakeError'));
        errorsShakeHub.dispatchEvent(
          new CustomEvent('setFocus', {
            detail: findFirstFieldWithError(values, errors),
          }),
        );
        setBtnPressed(false);
      }
    }, [isValid, btnPressed]);

    useEffect(() => {
      if (status === 'success') {
        setStatus();
      }
    }, [status]);

    return (
      <FormGrid
        submitHandler={e => {
          setBtnPressed(true);
          handleSubmit(e);
        }}
        formName={formName}
        id={id}
        className={className}
      >
        <FormRow sizes={['sm12']}>
          <FormColumn>
            <FormField Component={Input} {...fields.name} />
            <FormField Component={Input} {...fields.email} />
            <FormField Component={Input} {...fields.phone} />
            <FormField Component={Input} {...fields.message} />
          </FormColumn>
        </FormRow>
        {AdditionalInformationForForm}
        <div className={styles.submitWrap}>
          <FormSubmit title={'Register'} nda={fields.nda} disabled={isSubmitting} />
        </div>
        {captchaInfo && <CaptchaInfo className={styles.captcha} theme="bishkek" />}
      </FormGrid>
    );
  };

  const fields = {
    name: {
      initialValue: '',
      domProps: {
        placeholder: 'Name',
        maxLength: 500,
      },
    },
    email: {
      initialValue: '',
      inModal,
      domProps: {
        placeholder: 'Corporate email',
        type: 'email',
        maxLength: 128,
      },
    },
    phone: {
      initialValue: '',
      getInitialValue,
      mask: {
        mask: '+99999999999999999',
        maskChar: '',
        formatChars: {
          9: '[0-9]',
        },
      },
      domProps: {
        placeholder: 'Phone number',
        type: 'tel',
      },
    },
    message: {
      initialValue: '',
      isTextarea: true,
      domProps: {
        maxLength: 500,
        placeholder: 'Position',
      },
    },
  };

  if (withNda) {
    fields.nda = {
      initialValue: false,
      inModal,
      withoutError: true,
      content: 'I want to hide my personal data',
    };
  }

  const errorsMessages = {
    name: 'Please enter your full name',
    email: {
      required: 'Please enter your corporate email',
      notCorporateEmail: 'Please enter your corporate email',
    },
    phone: 'Please enter your phone number',
    message: 'Please enter your position',
  };

  const Component = withForm({
    fields,
    validation: props => corporateFormSchema(errorsMessages, props),
    submit: {
      id,
      formName,
      handler: Api.sendForm.bind(Api, 'integration/form-data'),
      reset: true,
      successModal: 'SuccessModal',
      errorModal: 'ErrorModal',
      onSuccess,
      additionalFormData,
      getAdditionalFormData,
      pushSubmitEvent: () => pushCustomSubmitEvent(id),
      onResponse: () => {
        if (inModal) {
          closeModal();
        }
      },
    },
  })(Form);

  return <Component />;
}

function getInitialValue() {
  if (typeof window !== 'undefined') {
    const country = getCountryCode()?.toLowerCase();
    return phoneCode[country] ?? '+';
  }
  return '+';
}
