import React, { lazy, Suspense } from 'react';
import cn from 'classnames';
import { useInView } from 'react-intersection-observer';
import { Container } from 'components/UI';
import { Title } from '@landing/ui';
import Slider from 'components/Slider';
import { useIsSsr } from 'utils/hooks';
import Slide from './Slide';

import * as styles from './Interviews.module.scss';

const Animation = lazy(() => import('components/UI/Animation'));

export default function Interviews({ title, subtitle, slides }) {
  const isSsr = useIsSsr();
  const [inViewRef, inView] = useInView({
    triggerOnce: true,
  });

  return (
    <Container className={styles.container} background="transparent">
      <div className={styles.layout}>
        <Title color="white">{title}</Title>
        <p className={styles.subtitle}>{subtitle}</p>
        <div className={cn(styles.sliderWrapper, 'defaultSlider')} ref={inViewRef}>
          {inView && (
            <Slider slides={slides}>
              {slides.map(slide => (
                <div key={slide.title} className={styles.slide}>
                  <Slide {...slide} />
                </div>
              ))}
            </Slider>
          )}
        </div>
        {!isSsr && (
          <Suspense fallback={null}>
            <Animation preserveAspectRatio className={styles.animation} name="about" />
          </Suspense>
        )}
      </div>
    </Container>
  );
}
